<template>
	<div>
        <div class="col-md-12">
            <div class="form-group">
                <label>Agregar {{ titulo }}:</label>
                <br>
                <a-upload-dragger
                    :multiple="false"
                    :customRequest="agregarImagenPortada"
                    :remove="handleRemovePortada"
                    :before-upload="beforeUploadPortada"
                    :file-list="form.fileListImagen"
                    name="image"
                    list-type="picture"
                    accept="image/jpeg, image/png, image/jpg"
                    @change="handleChangePortada"
                    @preview="handlePreviewPortada"
                >
                    <p class="ant-upload-drag-icon"><a-icon type="picture" /></p>
                    <p class="ant-upload-text">{{ titulo }}</p>
                    <p class="ant-upload-hint">
                        Haga clic o arrastre el archivo, solo .jpg o .png
                    </p>
                </a-upload-dragger>
                <a-modal 
                    :visible="previewVisibleImagen" 
                    :footer="null"
                    :width="820"
                    :zIndex="1110"
                    :dialog-style="{ top: '5px' }"
                    @cancel="handleCancelModalImagen"
                >
                    <img loading="lazy" style="width: 100%" :src="form.previewImagePortada" />
                </a-modal>
            </div>
        </div>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

        <a-modal 
            :visible="modalAyudaFormulario" 
            :footer="null"
            :width="380"
            :zIndex="1110"
            :dialog-style="{ top: '5px' }"
            @cancel="modalAyudaFormulario = false"
        >
            <center>
                <span>{{ textoAyudaFormulario }}</span><br>
                <img loading="lazy" style="width: 100%" :src="urlImgAyudaFormulario" />
            </center>
        </a-modal>
	</div>
</template>

<script>
    function getBase64File (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = error => reject(error)
		})
	}

    function blob2file (blobData, name) {
		const fd = new FormData()
		fd.set('a', blobData, name)
		return fd.get('a')
	}

	import * as utils from '@/utils/utils'
    import Compressor from 'compressorjs'

	export default {

        props: {
			titulo: {
				type: String,
				required: true
			}
		},

		data () {
			return {
                utils,
                form: {
                    imagen: [],
                    imagen_ruta: '',
                    fileListImagen: [],
                    previewImagePortada: ''
                },
                previewVisibleImagen: false,
                spinnerloading: false,
                loading: false,
                modalAyudaFormulario: false,
                urlImgAyudaFormulario: '',
                textoAyudaFormulario: ''
			}
		},

		methods: {

            agregarImagenPortada (info) {
                this.form.imagen = []
                console.log('Agregando Imagen')

				const $this = this
                /* eslint-disable no-new */
                new Compressor(info.file, {
					quality: 0.6,
					success (result) {
						const file = blob2file(result, result.name)
                        $this.form.imagen.push(file)
					},
					error (err) {
						console.log(err)
					}
				})
			},

            handleRemovePortada (info) {
                console.log('Remove Imagen')
                utils.openNotificationWithIcon('warning', 'Atencion:', 'Ha eliminado la Imagen...', 'topRight')
			},

            beforeUploadPortada (file) {
                console.log('Antes de cargar imagen')
                this.form.fileListImagen = []
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
				if (!isJpgOrPng) {
                    utils.openNotificationWithIcon('warning', 'Atencion:', 'Debe ser un archivo de imagen...', 'topRight')
				}
				const isLt1M = file.size / 5000 / 5000 < 3
				if (!isLt1M) {
                    utils.openNotificationWithIcon('warning', 'Atencion:', 'La imagen debe tener un tamaño maximo a 5 MB!', 'topRight')
				}
				return isJpgOrPng && isLt1M
			},

            handleChangePortada ({ fileList }) {
                console.log('Cambiando Imagen')
				this.form.fileListImagen = fileList
                setTimeout(function () {
                    const imagen = this.form.imagen
                    this.$emit('uploadImage', imagen)
                }.bind(this), 100)
			},

            async handlePreviewPortada (file) {
                console.log('Preview de Imagen')
				if (!file.url && !file.preview) {
					file.preview = await getBase64File(file.originFileObj)
				}
				this.form.previewImagePortada = file.url || file.preview
				this.previewVisibleImagen = true
			},

            handleCancelModalImagen () {
                console.log('Cancel Modal')
				this.previewVisibleImagen = false
			}
		}

	}
</script>
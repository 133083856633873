<template>
    <div>
        <v-swatches 
          v-model="color" 
          v-on="listeners"
          :swatches="swatches"
        ></v-swatches>
    </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
    components: { VSwatches },

    props: [
      'color'
    ],

    data () {
        return {
          swatches: ['#3B3B3B', '#3d556f', '#B30303', '#7A3101', '#014694', '#00785F', '#007812', '#120338', '#78006B']
        }
    },

    computed: {
        listeners () {
            return {
                ...this.$listeners,
                input: this.updateColorValue
            }
        }
    },
    
    methods: {
        updateColorValue (event) {
            this.$emit('successColor', event)
        }
    }
}
</script>